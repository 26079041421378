@import 'src/styles/variables';

.product_list {
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint-desktop) {
    margin-bottom: 24px;
  }
}

.page_header {
  margin-top: 16px;

  @media screen and (min-width: $breakpoint-desktop) {
    margin-bottom: 32px;
  }
}

.gridLayout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin: 0;

  @media (min-width: 768px) {
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.subheadingContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background: var(--color-L100);
  border-radius: 16px;
  padding: 16px;
  margin: 16px 2px 0 2px;
}

.subheading {
  line-height: 18px;
  font-size: 14px;
  color: var(--color-D60);

  @media screen and (min-width: 768px) {
    line-height: 20px;
  }
}
